import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef } from 'react';
import styles from './LocaleSelectPopup.module.scss';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onAnimationComplete: () => void;
}

export const LocaleSelectPopup = forwardRef<HTMLDivElement, Props>(
  function LocaleSelectPopupBase(
    { children, isOpen, onAnimationComplete },
    ref
  ) {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.popup}
            ref={ref}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            onAnimationComplete={onAnimationComplete}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);
