import React from 'react';
import {
  PageHead,
  PageHeadProps,
} from '@/containers/PageLayout/components/PageHead/PageHead';
import { RouterProgressBar } from '@/containers/PageLayout/components/RouterProgressBar';

export interface PageLayoutProps {
  children: React.ReactNode;
  navBar?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  metaTags?: PageHeadProps;
}

export const PageLayout = ({
  children,
  header,
  footer,
  metaTags,
  navBar,
}: PageLayoutProps) => (
  <>
    <PageHead {...metaTags} />
    <RouterProgressBar />
    {header}
    {navBar}
    {children}
    {footer}
  </>
);
