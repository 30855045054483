import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { NavLink } from '@/components/local/NavLink/NavLink';
import { getLangFromLocale } from '@/lib/i18n/getLangFromLocale';
import { useData } from '@/lib/pageData/pageDataContext';
import type { HubSpotFormOptions } from '@/types/HubSpotForm';
import styles from './FooterSubscribe.module.scss';

interface WindowWithHubspot extends Window {
  readonly hbspt?: {
    forms: {
      create: (opts: HubSpotFormOptions) => void;
    };
  };
}

export const FooterSubscribe = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { hubSpotFormConfig } = useData<'default'>();
  const [isFormCreated, setIsFormCreated] = useState(false);

  useEffectOnce(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      const hbspt = (window as WindowWithHubspot).hbspt;
      try {
        if (hbspt) {
          hbspt.forms.create({
            region: hubSpotFormConfig.region,
            portalId: hubSpotFormConfig.portalId,
            formId: hubSpotFormConfig.formId,
            target: '#hbsptForm',
            // The Hubspot form styles are located at `main.scss`,
            // because they have to be available before the component renders
            // so the styles are aplied correctly.
            // The styles can be also applied using the `...Class` props, but there is
            // no specific prop to style the input, thus the overrides are in `main.scss` file.
            // Read more: https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
            css: '',
            cssRequired: '',
            locale: getLangFromLocale(locale!),
          });

          setIsFormCreated(true);
        }
      } catch (error) {
        console.error(error);
      }
    });
  });

  if (!isFormCreated) return null;

  return (
    <>
      <H2 className={styles.heading} version="level3" color="white">
        {t('footer.subscribe.title')}
      </H2>
      <P color="white">{t('footer.subscribe.text')}</P>
      <div id="hbsptForm" />
      <P className={styles.privacyText} color="white">
        <Trans
          components={{
            navLink: (
              <NavLink
                className={styles.privacyLink}
                color="white"
                target="_blank"
                link={{
                  type: 'local',
                  url: '/privacy',
                  key: 'privacy',
                }}
              >
                link
              </NavLink>
            ),
          }}
          i18nKey="common:footer.subscribe.footnote"
        />
      </P>
    </>
  );
};
