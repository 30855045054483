import { useTranslation } from 'next-i18next';
import React from 'react';
import { Container } from '@/components/atoms/Layout/Layout';
import { Ul } from '@/components/atoms/List/List';
import { H2, Li } from '@/components/atoms/Typography/Typography';
import { IconBCorp } from '@/components/Icons/IconBCorp';
import { IconBrandLegacy } from '@/components/Icons/IconBrandLegacy';
import { Payments } from '@/components/molecules/Payments/Payments';
import { FooterLink } from '@/containers/PageLayout/components/Footer/FooterLink/FooterLink';
import { pushInteractionEvent } from '@/lib/gtm/gtmEvents';
import { useData } from '@/lib/pageData/pageDataContext';
import { LocaleSelect } from '../LocaleSelect/LocaleSelect';
import { PageSocialLinks } from '../PageSocialLinks/PageSocialLinks';
import styles from './Footer.module.scss';
import { FooterSubscribe } from './FooterSubscribe/FooterSubscribe';

export const Footer = () => {
  const { t } = useTranslation();
  const { navLinks, payments } = useData<'default'>();

  const footerLinks = [
    {
      heading: t('footer.heading1'),
      links: [
        navLinks.sustainability,
        navLinks.business,
        navLinks.jobs,
        navLinks.impressum,
        navLinks.modernSlaveryStatement,
        navLinks.press,
      ].filter(Boolean),
    },
    {
      heading: t('footer.heading2'),
      links: [navLinks.bikes, navLinks.testRide].filter(Boolean),
    },
    {
      heading: t('footer.heading3'),
      links: [
        navLinks.faq,
        navLinks.contact,
        navLinks.stores,
        navLinks.simpleTermination,
      ].filter(Boolean),
    },
  ];

  const footerLegalLinks = {
    legal: {
      links: [
        navLinks.terms,
        navLinks.privacy,
        navLinks.responsibleDisclosure,
        navLinks.cookies,
        navLinks.mentionslegales,
      ].filter(Boolean),
    },
  };

  return (
    <footer className={styles.footer}>
      <Container className={styles.container}>
        <div className={styles.logo}>
          <IconBrandLegacy className={styles.logoIcon} />
          <IconBCorp className={styles.bCorpLogoIcon} />
        </div>
        <section className={styles.linksSection}>
          {footerLinks.map(section => (
            <div key={section.heading} className={styles.linkColumn}>
              <H2
                className={styles.columnHeading}
                version="level3"
                color="white"
              >
                {section.heading}
              </H2>
              <Ul>
                {section.links.map(link => (
                  <Li
                    key={link.key}
                    onClick={() =>
                      pushInteractionEvent({
                        name: 'Footer link click',
                        details: link.url,
                      })
                    }
                  >
                    <FooterLink className={styles.columnLink} link={link} />
                  </Li>
                ))}
              </Ul>
            </div>
          ))}
          <div className={styles.subscribeColumn}>
            <FooterSubscribe />
          </div>
        </section>
        <section className={styles.legalSection}>
          <Payments className={styles.payments} list={payments} />
          <PageSocialLinks className={styles.socialLinks} />
          <LocaleSelect className={styles.localeSelect} />
          <Ul>
            {footerLegalLinks.legal.links.map(link => (
              <Li
                key={link.key}
                onClick={() =>
                  pushInteractionEvent({
                    name: 'Footer link click',
                    details: link.url,
                  })
                }
              >
                <FooterLink className={styles.legalLink} link={link} />
              </Li>
            ))}
          </Ul>
        </section>
      </Container>
    </footer>
  );
};
