import { forwardRef } from 'react';
import { AButton, AButtonProps } from '@/components/atoms/A/A';
import { Span } from '@/components/atoms/Typography/Typography';
import styles from './LocaleSelectTrigger.module.scss';

interface Props {
  chevronIcon?: React.ReactNode;
  flagIcon: React.ReactNode;
  languageCode: string;
}

export const LocaleSelectTrigger = forwardRef<
  HTMLButtonElement,
  AButtonProps & Props
>(function LocaleSelectTriggerBase(
  { chevronIcon, flagIcon, languageCode, onClick, ...rest },
  ref
) {
  return (
    <AButton ref={ref} className={styles.toggle} onClick={onClick} {...rest}>
      <div className={styles.flagIcon}>{flagIcon}</div>
      <Span className={styles.languageCode} color="white" version="body2">
        {languageCode}
      </Span>
      {chevronIcon}
    </AButton>
  );
});
