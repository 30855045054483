import {
  Root as AccordionRoot,
  AccordionSingleProps as AccordionSingleBaseProps,
  AccordionMultipleProps as AccordionMultipleBaseProps,
} from '@radix-ui/react-accordion';
import React, { useContext, useState } from 'react';

interface CustomProps {
  children: React.ReactNode;
  className?: string;
  name: string;
}

type AccordionSingleProps = Omit<AccordionSingleBaseProps, 'type'> &
  CustomProps;
type AccordionMultipleProps = Omit<AccordionMultipleBaseProps, 'type'> &
  CustomProps;

type Props = (AccordionSingleBaseProps | AccordionMultipleBaseProps) &
  CustomProps;

interface AccordionContextState {
  value: string | string[];
}

const AccordionBase = ({ children, className, name, ...rest }: Props) => (
  <AccordionRoot className={className} data-test-id={name} {...rest}>
    {children}
  </AccordionRoot>
);

export const AccordionSingle = ({
  children,
  defaultValue,
  ...rest
}: AccordionSingleProps) => {
  const [value, setValue] = useState(defaultValue || '');
  return (
    <AccordionBase
      {...rest}
      type="single"
      value={value}
      onValueChange={setValue}
    >
      <AccordionContext.Provider value={{ value }}>
        {children}
      </AccordionContext.Provider>
    </AccordionBase>
  );
};

export const AccordionMultiple = ({
  children,
  defaultValue,
  ...rest
}: AccordionMultipleProps) => {
  const [values, setValues] = useState<string[]>(defaultValue || []);
  return (
    <AccordionBase
      {...rest}
      type="multiple"
      value={values}
      onValueChange={setValues}
    >
      <AccordionContext.Provider value={{ value: values }}>
        {children}
      </AccordionContext.Provider>
    </AccordionBase>
  );
};

export const AccordionContext =
  React.createContext<AccordionContextState | null>(null);

export const useAccordion = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error('AccordionContext is empty');
  }

  return context;
};
