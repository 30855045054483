import { useTranslation } from 'next-i18next';
import React from 'react';
import { NavLink } from '@/components/local/NavLink/NavLink';
import { NavLinkTypeWithExternalLink } from '@/types/NavLinks';

interface Props {
  className?: string;
  link: NavLinkTypeWithExternalLink;
}

export const FooterLink = ({ link, className }: Props) => {
  const { t } = useTranslation();
  return (
    <NavLink
      link={link}
      version="body2"
      color="white"
      className={className}
      data-test-id={`footer-link-${link.key}`}
    >
      {t(`navLinks.${link.key}`)}
    </NavLink>
  );
};
