/**
 * Get the duration of the animation depending upon
 * the height provided.
 *
 * Following logic was borrowed from Material UI https://github.com/mui-org/material-ui.
 * https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
 * It allows for more realistic animation that scales with height/distance, which is needed and prevents crazy fast or super slow.
 */
export function getAutoHeightDuration(height: number) {
  if (!height) return 0;
  const constant = height / 36;
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}
