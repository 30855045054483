import cls from 'clsx';
import React from 'react';
import styles from './List.module.scss';

type UlProps = React.HTMLAttributes<HTMLUListElement>;

export const Ul = ({ children, className, ...rest }: UlProps) => {
  const classes = cls(styles.list, className);
  return (
    <ul {...rest} className={classes}>
      {children}
    </ul>
  );
};
