import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import { AButton } from '@/components/atoms/A/A';
import styles from './LocaleSelectBackButton.module.scss';

interface Props {
  className?: string;
  onClick: () => void;
}

export const LocaleSelectBackButton = ({ className, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <AButton
      aria-label={t('common:locale.goBack')}
      className={cls(styles.button, className)}
      onClick={onClick}
    >
      <FaChevronDown />
      <span>{t('common:locale.goBack')}</span>
    </AButton>
  );
};
