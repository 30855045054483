import cls from 'clsx';
import React from 'react';
import { PaymentLogo } from '@/containers/CheckoutPage/steps/Payment/components/PaymentLogo/PaymentLogo';
import { PaymentMethod } from '@/types/PageData';
import styles from './Payments.module.scss';

export interface PaymentsProps {
  list: PaymentMethod[];
  className?: string;
}

export const Payments = ({ list, className }: PaymentsProps) => (
  <div className={cls(styles.container, className)}>
    {list.map((paymentMethod: PaymentMethod) => (
      <div className={styles.iconContainer} key={paymentMethod.value}>
        <PaymentLogo paymentMethod={paymentMethod} iconSize="auto" />
      </div>
    ))}
  </div>
);
