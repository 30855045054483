import {
  AccordionContentProps as AccordionContentBaseProps,
  AccordionTriggerProps as AccordionTriggerBaseProps,
  AccordionItemProps,
  Content,
  Header,
  Item,
  Trigger,
} from '@radix-ui/react-accordion';
import cls from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';
import { useMeasure } from 'react-use';
import { AButton, AProps } from '@/components/atoms/A/A';
import { H3 } from '@/components/atoms/Typography/Typography';
import { useAccordion } from '@/components/molecules/Accordion/Accordion';
import { getAutoHeightDuration } from '@/utils/getAutoHeightDuration';
import styles from './AccordionItem.module.scss';

interface Props {
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
  name: string;
  title: React.ReactNode;
  value: AccordionItemProps['value'];
  version?: AProps['version'];
  onClick?: () => void;
}

interface AccordionContentProps extends AccordionContentBaseProps {
  isOpen: boolean;
}

interface AccordionTriggerProps extends AccordionTriggerBaseProps {
  version: AProps['version'];
}

const accordionContentVariants = {
  open: {
    opacity: 1,
    height: 'auto',
    display: 'block',
  },
  closed: { opacity: 0, height: 0, display: 'block' },
};

export const AccordionItem = ({
  ariaLabel,
  children,
  className,
  name,
  title,
  value,
  version,
  onClick,
}: Props) => {
  const { value: accordionValue } = useAccordion();

  const isOpen = Array.isArray(accordionValue)
    ? accordionValue.includes(value)
    : accordionValue === value;

  return (
    <Item
      className={cls(styles.accordionItem, className)}
      data-test-id={`${name}-accordion-item`}
      value={value}
    >
      <AccordionTrigger
        data-accordion-trigger=""
        aria-label={ariaLabel}
        className={styles.accordionTrigger}
        data-test-id={`${name}-accordion-button`}
        version={version}
        onClick={onClick}
      >
        {title}
      </AccordionTrigger>
      <AccordionContent
        className={styles.accordionContent}
        data-test-id={`${name}-accordion-panel`}
        isOpen={isOpen}
      >
        {children}
      </AccordionContent>
    </Item>
  );
};

const AccordionTrigger = ({
  children,
  className,
  version,
  ...props
}: AccordionTriggerProps) => (
  <Header asChild>
    <H3>
      <Trigger asChild className={className} {...props}>
        <AButton version={version}>{children}</AButton>
      </Trigger>
    </H3>
  </Header>
);

const AccordionContent = ({
  children,
  isOpen,
  ...rest
}: AccordionContentProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  return (
    <Content {...rest} asChild forceMount>
      <motion.div
        initial={isOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        inherit={false}
        variants={accordionContentVariants}
        transition={{
          ease: 'easeInOut',
          duration: getAutoHeightDuration(height) / 1000,
        }}
      >
        <div ref={ref}>{children}</div>
      </motion.div>
    </Content>
  );
};
