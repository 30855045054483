import cls from 'clsx';
import React from 'react';

import styles from './Layout.module.scss';

export type ContainerProps = React.HTMLAttributes<HTMLDivElement>;

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  function ContainerBase({ children, className, ...rest }, ref) {
    const classes = cls(className, styles.container);

    return (
      <div {...rest} ref={ref} className={classes}>
        {children}
      </div>
    );
  }
);

export type MobileOnlyProps = React.HTMLAttributes<HTMLDivElement>;

export const MobileOnly = React.forwardRef<HTMLDivElement, MobileOnlyProps>(
  function MobileOnlyBase({ children, className, ...rest }, ref) {
    const classes = cls(className, styles.mobileOnly);

    return (
      <div className={classes} ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);
