import { useTranslation } from 'next-i18next';
import React from 'react';
import { SocialLinks } from '@/components/molecules/SocialLinks';
import { useData } from '@/lib/pageData/pageDataContext';

interface Props {
  onClick?: () => void;
  className?: string;
}

export const PageSocialLinks = ({ onClick, className }: Props) => {
  const { t } = useTranslation();
  const { navLinks } = useData<'default'>();

  return (
    <SocialLinks
      onClick={onClick}
      className={className}
      urls={{
        facebook: navLinks.facebook.url,
        instagram: navLinks.instagram.url,
        linkedIn: navLinks.linkedIn.url,
        tiktok: navLinks.tiktok.url,
      }}
      title={{
        facebook: t('navLinks.facebook'),
        instagram: t('navLinks.instagram'),
      }}
    />
  );
};
